<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Rincian Tanggapan</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="clearData()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
            <div class="kt-list">
              <div
                class="kt-list__item"
                v-for="(item, index) in dataFeedback"
              >
                <span class="kt-list__text col-md-5 text-muted">{{ index }}</span>
                <span
                  class="kt-list__text col-md-7"
                  v-text="item"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataFeedback: {}
    };
  },
  props: {
    modalName: { type: String },
    tableName: { type: String },
    modalData: { type: Object }
  },
  methods: {
    setDetail: function (reservasi) {
      this.dataFeedback = reservasi;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.setDetail(this.modalData);
    }
  }
};
</script>
